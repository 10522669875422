var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paginated-table",attrs:{"id":_vm.id}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"dense":_vm.dense,"items":_vm.allItems,"must-sort":"","mobile-breakpoint":0,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{
      itemsPerPageOptions: [10, 25, 50, 100],
      itemsPerPageText: _vm.$t('Rows per page'),
    },"no-data-text":_vm.$t('No data available'),"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_vm._t("item",null,{"item":item})]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(_vm.$t('tableFooter', { pageStart: pageStart, pageStop: pageStop, itemsLength: itemsLength }))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }