import λ from '~/utils/invoker';
import debounce from 'debounce-promise';
export default function listMixin(resource) {
    const resourceλ = λ.namespaced(resource);
    return {
        data() {
            return {
                loading: false,
            };
        },
        methods: {
            fetchItems: resourceλ.action('fetchAll'),
            fetchItemsDebounced: debounce(λ.method('fetchItems'), 300),
            filterChanged() {
                this.query.page = 1;
                this.loading = true;
                this.fetchItemsDebounced(this.query)
                    .finally(() => {
                    this.loading = false;
                });
            },
        },
    };
}
;
