import { head } from 'ramda';
export default {
    name: 'paginated-table',
    props: {
        id: { type: String, required: true },
        headers: { type: Array, required: true },
        allItems: { type: Array, required: true },
        totalCount: { type: Number, required: true },
        pagination: { type: Object },
        dense: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
    },
    computed: {
        options: {
            get() {
                return Object.assign({}, this.pagination, {
                    sortBy: [this.pagination.sortBy],
                    sortDesc: [this.pagination.sortDesc]
                });
            },
            set(value) {
                const { sortBy, sortDesc, page, itemsPerPage } = value;
                this.pagination.sortBy = head(sortBy);
                this.pagination.sortDesc = head(sortDesc);
                this.pagination.page = page;
                this.pagination.itemsPerPage = itemsPerPage;
                this.$emit('pagination:change');
            }
        }
    },
};
